<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <v-btn
            dark
            color="secondary"
            class="ma-2"
            outlined
            @click="$router.back()"
          >
            <v-icon>mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </v-card-title>
        <div class="d-flex justify-space-between align-center mb-5">
          <v-card-title class="pl-6">
            <div class="d-flex flex-wrap">
              Course Items
              <v-tooltip v-if="requiredItems" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="red"
                    class="pl-1"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-information</v-icon
                  >
                </template>
                <span>The course is missing the necessary items</span>
              </v-tooltip>
            </div></v-card-title
          >

          <v-card-title
            class="d-flex justify-end flex-wrap align-center pa-0 ma-2"
          >
            <div class="select-filters">
              <v-select
                class="filter-input align-end"
                v-model="filters.parent"
                v-on:change="paginate()"
                :items="parents"
                item-text="label"
                item-value="value"
                hide-details
                single-line
                outlined
                dense
              ></v-select>
            </div>
            <v-btn
              class="ml-2"
              color="primary"
              @click="$router.push({ name: 'create-course-item' })"
            >
              <v-icon left>mdi-plus</v-icon>
              Create New
            </v-btn>
          </v-card-title>
        </div>

        <v-data-table
          :search="search"
          :headers="headers"
          :options.sync="options"
          :items="getCourseItems"
          :loading="getCourseItemsLoading"
          item-key="id"
          class="elevation-1 data-table"
        >
          <template v-slot:item.body_text="{ item }">
            <td class="pa-3">
              <template>
                <div v-html="item.body_text"></div>
              </template>
            </td>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-menu
              origin="bottom center"
              :offset-x="true"
              :offset-y="true"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" small v-bind="attrs" v-on="on">
                  Manage
                  <v-icon right class="ml-0">mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list dense class="py-1">
                <v-list-item
                  @click="
                    $router.push(
                      `/app/course/${item.course_id}/course-item/${item.id}/edit`
                    )
                  "
                >
                  <v-list-item-icon class="mr-1">
                    <v-icon dense>mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="isQuestions(item)"
                  @click="
                    $router.push(
                      `/app/course/${item.course_id}/course-item/${item.id}`
                    )
                  "
                >
                  <v-list-item-icon class="mr-1">
                    <v-icon dense>mdi-comment-question</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Questions</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="showRemoveDialog(item)">
                  <v-list-item-icon class="mr-1">
                    <v-icon dense>mdi-trash-can</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </base-card>
      <v-dialog v-model="dialog" max-width="300">
        <v-card v-if="selected">
          <v-card-title class="text-h6">
            Delete {{ selected.header }}
          </v-card-title>
          <v-card-text>
            The {{ selected.header }} course item will be deleted permanently.
            Are you sure?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false"> Cancel </v-btn>
            <v-btn color="danger" text @click="deleteItem"> Delete </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer.vue";
import { ITEM_TYPES } from "@/shared/constants";
import { find, get, isEmpty } from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo: {
    title: "Course Item",
  },
  components: {
    VideoPlayer,
  },

  data() {
    return {
      itemTypes: ITEM_TYPES,
      id: get(this.$route, "params.id"),
      options: {},
      search: "",
      selected: null,
      dialog: false,
      headers: [
        { text: "Header", value: "header", sortable: false },
        { text: "Item Type", value: "item_type.name", sortable: false },
        {
          text: "Body Text",
          value: "body_text",
          width: "40%",
          sortable: false,
        },
        { text: "Order", value: "order", sortable: false },
        { text: "Parent", value: "parent.header", sortable: false },
        { text: "Minimum Time", value: "minimum_time", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      filters: {
        parent: null,
      },
    };
  },

  computed: {
    ...mapGetters([
      "getCourseLookup",
      "getCourseItems",
      "getCourseParentItems",
      "getCourseItemsLoading",
    ]),

    course() {
      return get(find(this.getCourseLookup, { course_id: +this.id }), "course");
    },

    parents() {
      const selections = [];
      selections.push({
        label: "All Course Items",
        value: null,
      });

      this.getCourseParentItems.forEach((item) => {
        selections.push({
          label: item.header,
          value: item.id,
        });
      });

      return selections;
    },

    requiredItems() {
      return (
        isEmpty(this.getCourseItems) ||
        !find(this.getCourseItems, { item_type_key: ITEM_TYPES.INTRODUCTION })
      );
    },
  },

  watch: {
    options: {
      handler() {
        this.paginate();
      },
    },
    deep: true,
  },

  filters: {},

  methods: {
    ...mapActions(["setCourseItems", "deleteCourseItem"]),

    isQuestions(item) {
      return get(item, "item_type_key") === ITEM_TYPES.QUESTION;
    },

    paginate() {
      this.setCourseItems({
        id: this.id,
        ...this.filters,
      });
    },

    showRemoveDialog(item) {
      this.selected = item;
      this.dialog = true;
    },

    async deleteItem() {
      try {
        await this.deleteCourseItem(this.selected.id);
        this.paginate();

        this.selected = null;
        this.dialog = false;
      } catch (error) {
        console.error("DEBUG: error", error);
        this.$store.dispatch("showSnackbar", "Oops, something went wrong!");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
label {
  width: 130px !important;
}

.select-filters {
  .filter-input {
    margin: 0 0.5rem;
    width: 15em;
  }
}

.video-container {
  max-width: 550px;
  margin: 0 auto;
  color: white;
}

.text-start {
  margin: 5px;
}
</style>
